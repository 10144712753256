import React from 'react';

import { Global, css } from '@emotion/react';

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @import url('http://fonts.cdnfonts.com/css/sofia-pro');
        @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

        * {
          margin: 0;
          padding: 0;
        }

        *,
        *::before,
        *::after {
          box-sizing: inherit;
        }

        html {
          box-sizing: border-box;
          font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%

          @media only screen and (max-width: $bp-large) {
            font-size: 50%;
          }
        }

        body {
          font-family: 'Nunito', sans-serif;
          /* font-family: 'Sofia Pro', sans-serif; */
          font-weight: 400;
          line-height: 1.6;

          min-height: 100vh;
          position: relative;
        }

        .airplane {
          position: absolute;
          border-radius: 50%;
          filter: blur(19px);

          &__blue {
            background: #4fbec8 0% 0% no-repeat padding-box;
            filter: blur(19px);
            height: 4.8rem;
            width: 4.8rem;
            top: 27%;
            left: 0%;

            @media (min-width: 768px) {
              filter: blur(29px);
              height: 8.8rem;
              width: 8.8rem;
            }

            @media (min-width: 1280px) {
              height: 10.8rem;
              width: 10.8rem;
            }
          }

          &__yellow {
            background: #f7cf2b 0% 0% no-repeat padding-box;
            filter: blur(11px);
            height: 32px;
            width: 32px;
            top: 82%;
            left: 20%;

            @media (min-width: 768px) {
              filter: blur(22px);
              height: 62px;
              width: 62px;
            }

            @media (min-width: 1280px) {
              filter: blur(30px);
              height: 93px;
              width: 93px;
            }
          }

          &__red-one {
            background: #ea5055 0% 0% no-repeat padding-box;
            filter: blur(22px);
            height: 6rem;
            width: 6rem;
            top: 25%;
            left: 52%;

            @media (min-width: 768px) {
              filter: blur(32px);
              height: 11rem;
              width: 11rem;
            }

            @media (min-width: 1280px) {
              height: 15rem;
              width: 15rem;
            }
          }
          &__red-two {
            background: #ea5055;
            display: none;
            @media (min-width: 1280px) {
              display: block;
              height: 12rem;
              width: 12rem;
              top: 70%;
              left: -100px;
            }
          }
        }

        .service {
          position: absolute;
          border-radius: 50%;
          z-index: -1;

          &__yellow {
            background: #f7cf2b 0% 0% no-repeat padding-box;
            filter: blur(11px);
            height: 4rem;
            width: 4rem;
            left: 0;
            bottom: 20px;

            @media (min-width: 768px) {
              height: 8rem;
              width: 8rem;
              bottom: 80px;
            }

            @media (min-width: 1280px) {
              height: 10.8rem;
              width: 10.8rem;
              left: -100px;
              bottom: -40px;
            }
          }

          &__blue {
            height: 12rem;
            width: 12rem;
            background: #4fbec8;
            top: 23px;
            left: 93px;
            filter: blur(11px);

            @media (min-width: 1280px) {
              height: 15rem;
              width: 15rem;
              top: 6px;
              left: 70px;
            }
          }
        }

        .shadow {
          width: 165px;
          height: 32px;
          background: #a0a0a0 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          opacity: 0.17;
          filter: blur(5px);
          position: absolute;
          bottom: -10px;
          border-radius: 50%;
          @media (min-width: 992px) {
            width: 307px;
            right: 5%;
          }
        }
      `}
    />
  );
};
