import React from 'react';
import { ThemeProvider } from '@emotion/react';

import { GlobalStyles } from './styles/Global';

const theme = {
  colors: {
    primary: '#ed672b',
    dark: '#1F3463',
    info: '#4FBEC8',
    warning: '#F7CF2B',
    danger: '#EA5055',
    success: '#65B32E',
  },
};

const App = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  );
};

export default App;
